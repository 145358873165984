var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"dialogStyle":{ top: '10px' }},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('template',{slot:"footer"},[_c('a-button',{staticClass:"btn btn-outline-dark",on:{"click":_vm.handleCancel}},[_vm._v(" Cancel ")]),_c('a-button',{staticClass:"btn btn-outline-primary",attrs:{"loading":_vm.loading},on:{"click":_vm.saveChanges}},[_vm._v(" Submit ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-table',{attrs:{"dataSource":_vm.fieldlist,"pagination":{
            defaultPageSize: 100,
            showQuickJumper: true,
            hideOnSinglePage: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['100', '200', '300', '400', '500'],
          }}},[_c('a-table-column',{key:"selected",attrs:{"data-index":"selected"},scopedSlots:_vm._u([{key:"customRender",fn:function(text, record, index, column){return _c('a-checkbox',{attrs:{"checked":text == 1},on:{"change":(e) => _vm.selectedCheckbox(e, record, index, column)}})}}])},[_c('span',{attrs:{"slot":"title"},on:{"click":_vm.selectAll},slot:"title"},[_vm._v("Select")])]),_c('a-table-column',{key:"COLUMN_NAME",attrs:{"data-index":"COLUMN_NAME"},scopedSlots:_vm._u([{key:"customRender",fn:function(text){return [_c('label',[_vm._v(_vm._s(text))])]}}])},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Field name")])])],1)],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }