<template>
<div>
  <a-modal v-model="visible" :dialogStyle="{ top: '10px' }">
    <template slot="footer">
      <a-button class="btn btn-outline-dark" @click="handleCancel">
        Cancel
      </a-button>
      <a-button
        class="btn btn-outline-primary"
        :loading="loading"
        @click="saveChanges"
      >
        Submit
      </a-button>
    </template>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-table
          :dataSource="fieldlist"
          :pagination="{
            defaultPageSize: 100,
            showQuickJumper: true,
            hideOnSinglePage: true,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            pageSizeOptions: ['100', '200', '300', '400', '500'],
          }">
          <a-table-column key="selected" data-index="selected">
            <span slot="title" style="" @click="selectAll">Select</span>
            <a-checkbox
              slot="customRender"
              slot-scope="text, record, index, column"
              :checked="text == 1"
              @change="
                (e) => selectedCheckbox(e, record, index, column)
              "
            />
          </a-table-column>
          <a-table-column key="COLUMN_NAME" data-index="COLUMN_NAME">
            <span slot="title" style="">Field name</span>
            <template slot="customRender" slot-scope="text">
              <label>{{ text }}</label>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </div>
  </a-modal>
</div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
export default {
  data() {
    return {
      selectall: false,
      changed: false,
      visible: false,
      fieldlist: [],
      parentdata: [],
      loading: false,
    }
  },
  methods: {
    moment,
    handleCancel() {
      this.visible = false
    },
    selectAll() {
      if (this.selectall) {
        this.fieldlist.forEach(el => {
          el.selected = true
        })
        this.selectall = false
      } else {
        this.fieldlist.forEach(el => {
          el.selected = false
        })
        this.selectall = true
      }
    },
    selectedCheckbox(e, record, index, column) {
      record[column.dataIndex] = e.target.checked ? 1 : 0
      this.componentKey += 1
      this.changed = true
    },
    showModal(dbname, tablename, rowdata) {
      this.visible = true
      this.dbname = dbname
      this.tablename = tablename
      this.parentdata = rowdata
      this.getFieldList()
    },
    async getFieldList() {
      this.fieldlist = []
      var res = await lou.customUrlGet('manage/gridconfig/field_list?dbName=' + this.dbname + '&table_name=' + this.tablename, false, true)
      if (res) {
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i]
          var targetExist = this.parentdata.findIndex(x => x.field === element.COLUMN_NAME)
          if (targetExist >= 0) {
            element.id = this.parentdata[targetExist].id
            element.oldExist = true
          }
          element.selected = targetExist >= 0
          this.fieldlist.push(element)
        }
      }
    },
    async saveChanges() {
      this.loading = true
      var savedcreated = []
      var savedupdated = []
      var deleteddata = []
      if (this.fieldlist.length !== 0) {
        this.fieldlist.forEach(element => {
          var data = {
            table_name: this.tablename,
            field: element.COLUMN_NAME,
            headername: lou.capitalizeTheFirstLetterOfEachWord(element.COLUMN_NAME),
            editable: 'true',
            celleditor: null,
            valueformatter: element.DATA_TYPE === 'int' ? 'currency' : null,
            data_type: element.DATA_TYPE,
            reftable: null,
            retreffield: null,
            filter: 'true',
            resizable: 'true',
            sortable: 'true',
            pinned: '',
            minwidth: 75,
            maxwidth: 0,
            width: 0,
            ishide: 'false',
            cellrenderer: null,
            type: '',
            allownull: 0,
          }
          if (element.selected) {
            if (element.oldExist) {
              data.id = element.id
              savedupdated.push(data)
            } else {
              savedcreated.push(data)
            }
          } else {
            if (element.oldExist) {
              data.id = element.id
              deleteddata.push(data)
            }
          }
        })
        console.log('deleteddata', deleteddata)
        if (deleteddata.length !== 0) {
          var successdeleted = 0
          deleteddata.forEach(async (el, index) => {
            var resup = await lou.customUrlDelete('manage/gridconfig/' + this.dbname + '/' + this.tablename + '/' + el.id, false, true)
            if (resup) {
              successdeleted++
            }
            var last = index + 1
            if (last === deleteddata.length) {
              lou.shownotif('Removed', successdeleted + ' field has Removed')
              if (savedcreated.length === 0) {
                this.visible = false
                var res = await lou.readMaster('manage/gridconfig?dbname=' + this.dbname + '&table_name=' + this.tablename, false, true)
                this.$parent.rowData = res.data
              }
            }
          })
        }
        if (savedcreated.length !== 0) {
          var rescr = await lou.customUrlPost('manage/gridconfig/many?dbName=' + this.dbname + '&table_name=' + this.tablename, savedcreated, false, true)
          if (rescr) {
            this.visible = false
            var res = await lou.readMaster('manage/gridconfig?dbname=' + this.dbname + '&table_name=' + this.tablename, false, true)
            this.$parent.rowData = res.data
            lou.shownotif('Created', savedcreated.length + ' field has Created')
          }
        }
      }
      this.loading = false
    },
  },
}
</script>

<style>

</style>
